<template>
  <v-card
    v-bind="$attrs"
    outlined
    class="stack-card">
    <v-card-text class="stack-card__content">
      <CyStackAvatar
        class="stack-card__avatar"
        size="32"
        :ripple="showUseBtn && _.$isEmpty(stackImportStatus) || isStackImportInProgressOrFailed"
        :stack="service || {}"/>
      <div class="flex-grow-1">
        <div class="d-flex align-center">
          <CyTooltip
            :disabled="stackName.length < 30"
            top>
            <template
              #activator="{ on }">
              <span
                class="stack-card__name"
                v-on="on">
                {{ stackName }}
              </span>
            </template>
            {{ stackName }}
          </CyTooltip>

          <CyTooltip top>
            <template #activator="{ on }">
              <v-icon
                :key="stackRef"
                class="ml-1"
                size="16"
                color="#90A5C1"
                v-on="on">
                {{ isPublic ? 'public' : 'lock' }}
              </v-icon>
            </template>
            {{ $t(isPublic ? 'public' : 'private') }}
          </CyTooltip>

          <CyTooltip
            v-if="_.get(service, 'quota_enabled')"
            top>
            <template #activator="{ on }">
              <v-icon
                class="ml-1"
                size="16"
                color="warning"
                v-on="on">
                data_usage
              </v-icon>
            </template>
            {{ $t('quotas.requiresQuota') }}
          </CyTooltip>
        </div>
        <p class="stack-card__description mb-3">
          {{ description }}
        </p>
        <div class="stack-card__metadata">
          <CyHeaderMetadata
            v-for="provider of providers"
            :key="`service-${stackRef}-${provider}`"
            class="text__provider-images">
            <CyIconCredential
              size="22"
              :type="provider"
              show-tooltip
              tooltip-direction="top"
              :tooltip-text="_.$get(getProviderExtraInfoByCredType, provider, {}).displayNameFull"/>
          </CyHeaderMetadata>
          <CyHeaderMetadata
            icon="domain"
            :label="_.get(service, 'author')"/>
          <CyHeaderMetadata
            v-if="$showFeature.costEstimation"
            :label="'from XX $/month'"/>
          <CyHeaderMetadata
            v-if="_.get(service, 'trusted')"
            icon="verified_user"
            :label="$t('trusted')"/>
          <CyHeaderMetadata
            v-if="_.get(service, 'quota_enabled')"
            icon="data_usage"
            :label="$t('quotas.requiresQuota')"/>
          <CyTag
            v-if="isStackImportInProgressOrFailed"
            :variant="isStackImport.importing ? 'accent' : 'error'"
            @click="$emit('show-import-progress-modal', service)">
            {{ stackImportStatusText }}
          </CyTag>
        </div>
      </div>

      <div class="stack-card__actions">
        <CyButton
          v-if="showDetailsBtn"
          v-has-rights-to="'GetServiceCatalog'"
          class="details-btn"
          theme="primary"
          variant="secondary"
          sm
          v-bind="detailsBtnAction ? {} : detailsBtnBindings"
          @click.native="detailsBtnAction ? detailsBtnAction() : null">
          {{ $t('preview') }}
        </CyButton>
        <CyButton
          v-if="showUseBtn"
          v-has-rights-to="'CreateProject'"
          class="ml-3"
          sm
          theme="secondary"
          variant="primary"
          data-cy="use-this-stack"
          @click.native="$emit('selected', service)">
          {{ $t('forms.btnSelect') }}
        </CyButton>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { retryInfraImportIntervalTimer } from '@/store/modules/organization/infraImport'
import CyStackAvatar from '@/components/stack-avatar.vue'
import CyHeaderMetadata from '@/components/header/metadata.vue'

export const defaultServiceImage = '/static/images/stack_default.png'
export const MAX_DESCRIPTION_LENGTH = 120

export default {
  name: 'CyWizardServiceCard',
  components: {
    CyStackAvatar,
    CyHeaderMetadata,
  },
  props: {
    service: {
      type: Object,
      default: () => ({}),
    },
    showDetailsBtn: {
      type: Boolean,
      default: false,
    },
    detailsBtnAction: {
      type: Function,
      default: null,
    },
    showUseBtn: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    animatedDots: '...',
    animatedDotsRefreshIntervalID: null,
    infraImportRefreshIntervalID: null,
  }),
  computed: {
    ...mapGetters('organization/infraImport', [
      'infraImportStatus',
    ]),
    ...mapGetters('organization/cloudCostManagement', [
      'getProviderExtraInfoByCredType',
    ]),
    stackImportStatus () {
      const { service, infraImportStatus, stackRef } = this
      const stackImportStatusFromLogs = infraImportStatus(stackRef)
      const stackImportStatus = _.$get(service, 'import_status', '')
      return stackImportStatusFromLogs || stackImportStatus
    },
    stackImportStatusText () {
      const trailingDots = this.isStackImport.importing ? this.animatedDots : ''
      return `${_.upperFirst(this.$t(this.stackImportStatus))}${trailingDots}`
    },
    isStackImportInProgressOrFailed () {
      const { isStackImport: { failed, importing } } = this
      return failed || importing
    },
    isStackImport () {
      return { [this.stackImportStatus]: true }
    },
    isPublic () {
      return this.service?.status === 'public'
    },
    providers () {
      const { keywords = [] } = this.service || {}

      return keywords
        .filter((keyword) => keyword.startsWith('provider:'))
        .sort()
        .map((providerId) => providerId.split(':')[1])
    },
    stackName () {
      return this.service?.name ?? ''
    },
    description () {
      const { description = '' } = this.service || {}

      return description.length < MAX_DESCRIPTION_LENGTH
        ? description
        : description.slice(0, MAX_DESCRIPTION_LENGTH) + '...'
    },
    stackRef () {
      return this.service?.ref
    },
    detailsBtnBindings () {
      const { stackRef, $route } = this
      const comeBackToThisPage = ['environments'].includes($route.name)
      const openInNewTab = ['infraImport', 'newService'].includes($route.name)
      return {
        to: {
          name: 'stack',
          params: {
            stackRef,
            ...(comeBackToThisPage ? { ...$route.params, backRouteTo: $route.name } : {}),
          },
        },
        ...(openInNewTab ? { target: '_blank' } : {}),
      }
    },
  },
  watch: {
    stackImportStatus: {
      handler (status) {
        window.clearInterval(this.infraImportRefreshIntervalID)
        window.clearInterval(this.animatedDotsRefreshIntervalID)
        if (status === 'importing') {
          const { stackRef } = this
          this.infraImportRefreshIntervalID = window.setInterval(() => this.GET_STACK_INFRA_IMPORT_STATUS({ stackRef }), retryInfraImportIntervalTimer)
          this.animatedDotsRefreshIntervalID = window.setInterval(() => this.animateDots(), 1000)
        }
      },
      immediate: true,
    },
  },
  beforeDestroy () {
    window.clearInterval(this.infraImportRefreshIntervalID)
    window.clearInterval(this.animatedDotsRefreshIntervalID)
  },
  methods: {
    ...mapActions('organization/infraImport', [
      'GET_STACK_INFRA_IMPORT_STATUS',
    ]),
    animateDots () {
      this.animatedDots = this.animatedDots.length > 2 ? '' : `${this.animatedDots}.`
    },
  },
  i18n: {
    messages: {
      en: {
        failed: 'import failed',
        private: 'Private',
        public: 'Public',
        trusted: 'Trusted',
        updated: 'Updated {0}',
      },
      es: {
        failed: 'importación fallida',
        private: 'Privada',
        public: 'Pública',
        trusted: 'De confianza',
        updated: 'Actualizado {0}',
      },
      fr: {
        failed: 'manqué',
        private: 'Privée',
        public: 'Publique',
        trusted: 'De confiance',
        updated: 'Mis à jour {0}',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.stack-card {
  &__content {
    display: flex;
    gap: $spacer * 4;
  }

  &__avatar {
    min-width: 0;
  }

  &__name {
    color: get-color("primary");
    font-size: map.get($font-sizes, "lg");
    font-weight: $font-weight-bold;
    line-height: $line-height-sm;
  }

  &__actions {
    display: flex;
    justify-content: right;
  }

  &__metadata {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
