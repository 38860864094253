<template>
  <v-chip
    :ripple="false"
    color="transparent"
    label
    data-cy="chip"
    :to="to"
    v-on="$listeners">
    <slot>
      <v-icon
        v-if="icon"
        size="18"
        class="cy-header-metadata__icon"
        data-cy="default-icon">
        {{ icon }}
      </v-icon>
    </slot>
    {{ label }}
  </v-chip>
</template>

<script>
export default {
  name: 'CyHeaderMetadata',
  props: {
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    to: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
$avatar-size: 20px;

.cy-header-metadata__icon {
  &,
  ::v-deep & {
    margin-right: $spacer;
  }
}

.v-chip {
  position: relative;
  overflow: visible;
  height: auto;
  color: get-color("primary", "light-2");
  padding: 0;

  &:hover::before {
    opacity: 0;
  }

  &:not(:last-child)::after {
    content: "\2022";
    padding-inline: 2 * $spacer;
    color: get-color("primary", "light-4");
  }

  .v-icon {
    color: get-color("primary", "light-3");
  }

  ::v-deep .cy-avatar {
    width: $avatar-size;
    min-width: $avatar-size;
    height: $avatar-size;
    margin-right: $spacer;
  }

  &.v-chip--link {
    font-weight: $font-weight-normal;
    color: get-color("primary", "light-2");

    &:hover {
      color: get-color("primary", "light-2");

      ::v-deep .v-chip__content {
        text-decoration: underline;
      }
    }

    &:active {
      box-shadow: none;
    }

    &::before {
      content: none;
    }
  }
}
</style>
